import React from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Text,
} from 'rebass/styled-components';
import { useQuery } from '@apollo/client';
import Link from 'gatsby-link';
import Layout from '../components/Layout';
import getNewsQuery from '../graphql/queries/getNews.graphql';
import SingleNewsItem from '../components/SingleNewsItem';

const News = () => {
  const { data, loading } = useQuery(getNewsQuery);
  if (loading && !data) {
    return null;
  }
  return (
    <Layout>
      <Flex variant={'wrapper'} flexDirection={'column'} color={'text'}>
        <Heading
          marginLeft={20}
          mb={[25, 50]}
          fontSize={[24, 32]}
          fontFamily={'Oswald'}
          color={'text'}
        >
          Aktualności
        </Heading>
        {data.getNews.map((singleNews) => {
          return (
            <SingleNewsItem
              key={singleNews._id}
              singleNews={singleNews}
              newsUrl={`/aktualnosci/${singleNews.slug}`}
            />
          );
        })}
      </Flex>
    </Layout>
  );
};

export default News;
